html, body {
    background-color: #111111;
    color: rgba(255, 255, 255, .97);
    
    overflow: hidden;
    
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

* {
    font-family: Roboto, sans-serif;
}

body > canvas {
    position: absolute;
    top: 0;
    left: 0;

    image-rendering: pixelated;
}

#settings {
    position: absolute;
    top: 10px;
    left: 10px;

    z-index: 5;

    padding: 0 !important;
}

input {
    position: relative;
    top: 4px;
    margin-left: 10px;
}

#debug {
    margin-bottom: 10px;
    font-family: monospace;
    white-space: pre;

    font-size: 12px;
}

#loading {
    position: absolute;
    top: 50%;
    left: 50%;

    padding: 10px;

    text-align: center;

    font-size: 28px;    

    transform: translate(-50%, -50%);

    z-index: 10;
}

#loading.hide {
    display: none;
}

#settings, #loading, #locationsContainer, #introduction {
    background-color: rgba(0, 0, 0, .6);
    border: 3px solid rgba(50, 50, 50, .6);

    padding: 10px;

    user-select: none;
}

#introduction {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 40%;
    min-width: 300px;

    text-align: center;
    white-space: pre-wrap;

    z-index: 99;
}

#locationsContainer {
    position: absolute;
    right: 10px;
    top: 10px;
    
    width: 20%;
}

#locationsContainer > :first-child > h1 {
    font-size: 16px;
    width: fit-content;

    margin-bottom: 5px;
}

#locationsContainer > :first-child > * {
    margin: 0 auto;
}

#locationsContainer > :first-child {
    margin: 0 auto 35px auto;

    width: 93%;
}

#locationsContainer > :first-child > span {
    float: left;
    font-size: 14px;
}

#locationsContainer > :first-child > input {
    float: right;
}

#locations {
    display: flex;

    flex-direction: row;

    width: 100%;

    flex-flow: row wrap;
    justify-content: space-between;
}

#locations > button {
    margin: 5px 10px 5px 10px;

    padding: 5px;

    cursor: pointer;

    text-align: center;

    color: #fff;

    border: 2px solid rgba(0, 0, 0, .8);
    background-color: rgba(255, 255, 255, .1);

    font-size: 14px;

    outline: none !important;
}

#locations > button:hover {
    background-color: rgba(255, 255, 255, .3);
}

#locations > button:active {
    background-color: rgba(255, 255, 255, .5);
}

#locationInterpolateProgressBar {
    position: absolute;
    top: 0;
    left: 0;

    -webkit-appearance: none;
    appearance: none;

    width: 100%;
    height: 15px;
    
    border-radius: 0;

    display: none;

    opacity: 0.5;
}

#locationInterpolateProgressBar.show {
    display: block;
}

#locationInterpolateProgressBar::-webkit-progress-bar {
    background-color: transparent;
}

#locationInterpolateProgressBar::-webkit-progress-value {
    background-color: #fff;
}

kbd {
    display: inline-block;
    padding: 3px 5px;

    font: 12px monospace;

    line-height: 11px;

    color: rgb(183, 177, 168);

    vertical-align: middle;

    background-color: rgb(26, 28, 30);
    border: 1px solid rgb(60, 65, 67);

    border-radius: 6px;
    box-shadow: inset 0 -1px 0 rgb(47, 51, 53);
}


.collapsible {
    background-color: rgba(0, 0, 0, .2);
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}
  
.active, .collapsible:hover {
    background-color: rgba(0, 0, 0, .4);
}

.collapsible.active + .content {
    padding: 10px;
}
  
.collapsible:after {
    content: '\002B';
    color: white;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}
  
.active:after {
    content: "\2212";
}
  
.content {
    max-height: 0;
    overflow: hidden;

    padding: 0 10px;

    transition: padding 0.2s ease-out;

    background-color: rgba(255, 255, 255, .05);
}

.content > .collapsible, .content > .content {
    position: relative;
    left: -10px;

    width: calc(100% + 20px);
}

.separator {
    height: 10px;
    width: 100%;
    margin: 0;
    padding: 0;
}

.no-bottom-padding {
    padding-bottom: 0 !important;
}